import React, { ReactElement, useEffect } from 'react';
import { FavoriteAppData } from '../../../types';
import {
  StyledSectionTitle,
  FavoritesAppContainer,
  FavoriteSectionHeader,
  FavoriteSectionHeaderCols,
} from './FavoriteAppSection.styles';
import { useTranslation } from 'react-i18next';
import PaginationSection from '../PaginationSection';
import FavoriteEmptySection from '../FavoriteEmptySection';
import FavoriteAppMenu from '../FavoriteAppMenu/FavoriteAppMenu';
import { CombinedData } from '../../hooks/queries/useCombinedGlobalNavData';
import SnackBarToast from '../SnackBarToast';
import favoriteAppStore from '../../helpers/favoriteGlobalStore/FavoriteAppStore';

interface FavoriteAppSectionProps {
  toggleFavoriteSection: (menuItem: string) => void;
  isFavoriteSectionVisible: boolean;
  data: CombinedData;
}

const FavoriteAppSection = ({
  toggleFavoriteSection,
  isFavoriteSectionVisible,
  data,
}: FavoriteAppSectionProps): ReactElement => {
  const { t } = useTranslation();
  const [openToast, setOpenToast] = React.useState(false);
  const [favoriteAppsList, setFavoriteAppsList] = React.useState<FavoriteAppData[]>([]);

  useEffect(() => {
    setFavoriteAppsList(favoriteAppStore.getFavoritesApps());
  });
  const onSaveFavoriteApps = () => {
    setFavoriteAppsList(favoriteAppStore.getFavoritesApps());
    setOpenToast(true);
  };
  return (
    <>
      <FavoriteSectionHeader>
        <FavoriteSectionHeaderCols>
          <StyledSectionTitle variant="h2">
            {isFavoriteSectionVisible && t('favoriteApps.section_title')}
          </StyledSectionTitle>
          <FavoriteAppMenu
            toggleFavoriteSection={toggleFavoriteSection}
            data={data}
            onSaveFavoriteApps={onSaveFavoriteApps}
          />
        </FavoriteSectionHeaderCols>
      </FavoriteSectionHeader>
      {isFavoriteSectionVisible && (
        <FavoritesAppContainer>
          {favoriteAppsList && favoriteAppsList.length > 0 ? (
            <PaginationSection FavoriteAppsData={favoriteAppsList} />
          ) : (
            <FavoriteEmptySection
              cardCount={3}
              footerSubtitle={t('favoriteApps.section_sub_title')}
            ></FavoriteEmptySection>
          )}
        </FavoritesAppContainer>
      )}
      <SnackBarToast
        open={openToast}
        onToastClose={() => setOpenToast(false)}
        message={t('favoriteApps.save_message')}
        vertical="top"
        horizontal="right"
      ></SnackBarToast>
    </>
  );
};

export default FavoriteAppSection;
