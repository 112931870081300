import { Typography, useTheme } from '@mui/material';
import styled from '@emotion/styled';

export const CardContainer = styled.div((props: any) => {
  const { tokens } = useTheme();

  const generateCursorType = (componentType: string) => {
    if (componentType == 'default') {
      return 'pointer';
    } else if (componentType == 'draggable') {
      return 'grab';
    } else {
      return 'default';
    }
  };

  return {
    border: props.componentType == 'skeleton' ? 'none' : `1px solid ${tokens.semantic.color.ui.divider.value}`,
    borderRadius: tokens.core.spacing['1_5'].value,
    padding: tokens.core.spacing['1_5'].value,
    background: tokens.semantic.color.background.base.value,
    maxWidth: '382px',
    minWidth: '220px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    cursor: generateCursorType(props.componentType),
  };
});

export const IconContainer = styled.div((props: any) => {
  const { tokens, palette } = useTheme();

  return {
    backgroundColor: props.componentType == 'skeleton' ? palette.grey[300] : palette.grey[900],
    borderRadius: tokens.semantic.radius.md.value,
    color: palette.grey[50],
    height: `${tokens.core.spacing[5].value}`,
    width: `${tokens.core.spacing[5].value}`,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  };
});

export const StyledAppNameText = styled(Typography)(({ fontSize, fontWeight }) => {
  const { tokens } = useTheme();
  const { semantic } = tokens;

  return {
    fontWeight: semantic.fontWeight.emphasis.value,
    fontSize: '14px',
    lineHeight: '20px !important',
  };
});

export const StyledSectionNameText = styled(Typography)(() => {
  const { tokens } = useTheme();
  const { semantic } = tokens;

  return {
    fontWeight: semantic.fontWeight.regular.value,
    fontSize: '11px',
    lineHeight: '16px !important',
  };
});

export const CardIconDataContainer = styled.div(() => {
  const { tokens } = useTheme();
  return {
    display: 'flex',
    alignItems: 'center',
    columnGap: tokens.core.spacing['1_5'].value,
  };
});

export const SkeletonCardIconDataContainer = styled.div(() => {
  const { tokens } = useTheme();
  return {
    display: 'flex',
    alignItems: 'center',
    columnGap: tokens.core.spacing['1_5'].value,
    width: '100%',
  };
});

export const SkeletonContainer = styled.div(() => {
  const { tokens } = useTheme();
  return {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    rowGap: tokens.core.spacing['1_5'].value,
    alignItems: 'flex-start',
  };
});

export const SkeletonLines = styled.div((props: any) => {
  const { tokens, palette } = useTheme();
  return {
    width: props.width,
    height: tokens.core.spacing['1'].value,
    borderRadius: tokens.core.spacing['0_5'].value,
    backgroundColor: palette.grey[200],
  };
});

export const FavoriteActionButton = styled.button(() => {
  const { tokens } = useTheme();
  return {
    background: tokens.semantic.color.background.base.value,
    padding: '0px',
    border: 'none',
    cursor: 'pointer',
  };
});
