import React, { ReactElement } from 'react';
import { Snackbar, Alert, Button } from '@mui/material';
import Close from '@diligentcorp/atlas-react-icons/dist/esm/lens/Close.js';

interface SnackBarToastProps {
  vertical: 'top' | 'bottom';
  horizontal: 'left' | 'center' | 'right';
  open: boolean;
  onToastClose: () => void;
  message: string;
}

const DismissButton = ({
  dismissClicked = () => {
    console.log('dismiss button clicked');
  },
}) => (
  <Button variant={'tertiary'} size="medium" onClick={dismissClicked}>
    {<Close />}
  </Button>
);

function getActionButton(closeToast: () => void) {
  return <DismissButton dismissClicked={closeToast} />;
}

const SnackBarToast = ({ vertical, horizontal, open, onToastClose, message }: SnackBarToastProps): ReactElement => {
  return (
    <Snackbar
      anchorOrigin={{ vertical, horizontal }}
      open={open}
      onClose={onToastClose}
      message={message}
      key={vertical + horizontal}
    >
      <Alert action={getActionButton(onToastClose)} severity="success" variant={'standard'} aria-live="polite">
        {message}
      </Alert>
    </Snackbar>
  );
};
export default SnackBarToast;
